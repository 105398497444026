import React from "react";
import {
  ModalComponentWrapper,
  TableDetail,
  TableCell,
  TableRow,
  TitleInfo,
  ValueInfo,
} from "./styled";

const ModalDetailAdmin = ({
  onOk,
  onCancel,
  visibleModal,
  dataDetail
}) => {
  return (
    <ModalComponentWrapper
      open={visibleModal}
      onOk={onOk}
      onCancel={onCancel}
      centered
    >
      <TableDetail>
        <tbody>
          {dataDetail && Object.entries(dataDetail).map(([key, value]) => (
            <TableRow key={key}>
              <TableCell>
                <TitleInfo>{key}</TitleInfo>
              </TableCell>
              <TableCell>
                <ValueInfo>{value}</ValueInfo>
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </TableDetail>
    </ModalComponentWrapper>
  );
};

export default ModalDetailAdmin;
